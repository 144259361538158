<div class="dams-dialog">
  <div class="dams-dialog__header">
    <span class="dams-dialog__header-label">
      {{ operationContainer.currentOperation.menu_title | translate }}
    </span>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>
        clear
      </mat-icon>
    </button>
  </div>

  <div class="dams-dialog__search">
    <mat-form-field class="dams-dialog__form-field">
      <input
        matInput
        class="dams-dialog__input"
        [placeholder]="'TRANS__DAMS_DIALOG__SEARCH_DAMS' | translate"
        [(ngModel)]="query"
      />

      <mat-icon matSuffix>
        search
      </mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'TRANS__DAMS_DIALOG__MEDIA_TYPE' | translate }}
      </mat-label>
      <mat-select (valueChange)="selectType($event)" [value]="selectedType">
        <mat-option value="all">
          {{ 'TRANS__DAMS_TYPE__ALL' | translate }}
        </mat-option>
        @for (type of damsService.getAvailableTypes(); track $index) {
          <mat-option [value]="type.value">
            {{ type.label | translate }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div class="dams-dialog__result-header">
    <span class="dams-dialog__result-label">
      {{ totalCount() }} {{ 'TRANS__DAMS_DIALOG__RESULT_COUNT' | translate }}
    </span>

    <button mat-button>
      <mat-icon matPrefix>
        sort_by_alpha
      </mat-icon>
      Sortering
    </button>

    <mat-paginator
      (page)="pageChange($event)"
      [hidePageSize]="true"
      [length]="totalCount()"
      [pageIndex]="pageNumber"
      pageSize="40"
    ></mat-paginator>
  </div>

  @if(loading) {
    <div class="dams-dialog__result-loader">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  }

  @if(!loading && searchResults().length > 0) {
    <div class="dams-dialog__result">
      @for(item of searchResults(); track $index) {
        <app-operation-view-dams-dialog-item
          (batchToggleItems)="batchToggleItems($event)"
          [index]="$index"
          [item]="item"
          [image]="getImage(item.dmsf_id)"
        />
      }
    </div>
  }

  @if(!loading && searchResults().length === 0) {
    <div class="dams-dialog__result-loader">
      <span>
        Ingen resultater...
      </span>
    </div>
  }

  <div class="dams-dialog__footer">
    <div class="dams-dialog__footer-label">
      <span class="dams-dialog__step-label">
        Steg 1/2
      </span>
      <span class="dams-dialog__step-description-label">
        Marker et element for å gå videre
      </span>
    </div>

    <button mat-button (click)="closeDialog()">
      Avbryt
    </button>

    <button mat-flat-button>
      Fortsett
    </button>
  </div>
</div>
