<mat-tree class="filter-hierarchy" [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node class="filter-hierarchy__node" *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="8">
    <div class="filter-hierarchy__node-container" [ngClass]="{ 'filter-hierarchy__node-container--selected' : node.filter.facet.selected }">
      <button mat-icon-button disabled class="filter-hierarchy__node-button"></button>

      <span class="filter-hierarchy__node-label" (click)="checkNode(node)" [ngClass]="{ 'filter-hierarchy__node-label--selected' : node.filter.facet.selected }">
        {{ node.name }}
      </span>

      <span class="filter-hierarchy__node-count-label" [ngClass]="{ 'filter-hierarchy__node-count-label--selected' : node.filter.facet.selected }">
        {{ node.filter.facet.count }}
      </span>

      @if(node.filter.facet.selected) {
        <mat-icon class="filter-hierarchy__node-clear-icon">
          close
        </mat-icon>
      }
    </div>
  </mat-tree-node>

  <mat-tree-node class="filter-hierarchy__node" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="8" matTreeNodeToggle>
    <div class="filter-hierarchy__node-container" [ngClass]="{ 'filter-hierarchy__node-container--selected' : node.filter.facet.selected }">
      <button mat-icon-button class="filter-hierarchy__node-button" [attr.aria-label]="'Toggle ' + node.shortName">
        <mat-icon class="filter-hierarchy__node-icon" [ngClass]="{ 'filter-hierarchy__node-icon--rotated' : treeControl.isExpanded(node), 'filter-hierarchy__node-icon--selected' : node.filter.facet.selected }">
          arrow_right
        </mat-icon>
      </button>

      <span class="filter-hierarchy__node-label" (click)="checkNode(node)" [ngClass]="{ 'filter-hierarchy__node-label--selected' : node.filter.facet.selected }">
        {{ node.name }}
      </span>

      <span class="filter-hierarchy__node-count-label" [ngClass]="{ 'filter-hierarchy__node-count-label--selected' : node.filter.facet.selected }">
        {{ node.filter.facet.count }}
      </span>

      @if(node.filter.facet.selected) {
        <mat-icon class="filter-hierarchy__node-clear-icon">
          close
        </mat-icon>
      }
    </div>
  </mat-tree-node>
</mat-tree>
