import { Injectable } from '@angular/core';
import {AccessTokenService} from "./access-token.service";
import {HttpHeaders} from "@angular/common/http";
import { v4 as uuid } from 'uuid';
import {environment} from "../../environments/environment";
import {PrimusBackendInstanceService} from "./primus-backend-instance.service";

export interface DamsSearchRequest {
  q?: string;
  sort: string;
  rows: number;
  start: number;
  document_type?: string[];
  museums: string;
  uploaded_by?: number;
  copyright?: string;
  subjects?: string[];
  persons?: string[];
  producers?: string[];
  licenses?: string[];
}

export interface DamsTypeItem {
  count: number;
  value: string;
  label: string;
  icon: string;
}

@Injectable({
  providedIn: 'root'
})
export class DamsService {
  baseUrl = environment.ekulturAPIGatewayURL + '/dams/api/v1/';

  allowedTypes: string[] = [
    'StillImage',
    'Audio',
    'Video',
    'Modell',
    'Dokument'
  ]
  availableTypes: DamsTypeItem[] = [];

  constructor(
    private accessTokenService: AccessTokenService
  ) {
    this.getAvailableTypesFromDams().then();
  }

  getAvailableTypes() {
    return this.availableTypes;
  }

  getHeaders() {
    return {
      'Accept': 'application/json',
      'AppId': 'bfb1b23a-97a9-483e-99e2-fa61e4b5b7c6',
      'Authorization': `Bearer ${this.accessTokenService.getToken()}`,
      'Content-Type': 'application/json',
      'Correlation-Id': uuid()
    }
  }

  async search(params: DamsSearchRequest) {
    return new Promise((resolve, reject) => {
      this.execFetch('search', {...params}, 'POST', resolve, reject);
    })
  }

  private async execFetch(path: string, params: DamsSearchRequest | {}, method: 'GET' | 'POST', resolve, reject) {
    const headers = this.getHeaders();

    let rawResponse = method === 'GET'
      ? await fetch(`${this.baseUrl}${path}`, {
        method: method,
        headers: headers
      })
      : await fetch(`${this.baseUrl}${path}`, {
      method: method,
      headers: headers,
      body: JSON.stringify(params)
    });

    if (rawResponse.ok) {
      resolve(await rawResponse.json());
    } else {
      reject(await rawResponse.json());
    }
  }

  private async getAvailableTypesFromDams() {
    let response = await fetch(`${this.baseUrl}statistics/${PrimusBackendInstanceService.getInstanceDetails().id}`, {
      method: 'GET',
      headers: this.getHeaders()
    });

    if (response.ok) {
      this.updateAvailableTypes(await response.json());
    }
  }

  private getIconFromName(name: string): string {
    return 'photo_size_select_actual';
  }

  private updateAvailableTypes(data: any) {
    for (let index = 0; index < data.length; index += 2) {
      if(this.allowedTypes.includes(data[index]) && data[index + 1] > 0) {
        this.availableTypes.push({
          count: data[index + 1],
          value: data[index],
          label: `TRANS__DAMS_TYPE__${data[index].toUpperCase()}`,
          icon: this.getIconFromName(data[index])
        })
      }
    }
  }
}
