import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {DamsGridItem} from "../operation-view-dams-dialog.component";
import {NgClass} from "@angular/common";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-operation-view-dams-dialog-item',
  standalone: true,
  imports: [
    MatIcon,
    NgClass,
    MatCheckbox,
    FormsModule
  ],
  templateUrl: './operation-view-dams-dialog-item.component.html',
  styleUrl: './operation-view-dams-dialog-item.component.scss'
})
export class OperationViewDamsDialogItemComponent {
  @Input() index: number;
  @Input() item: DamsGridItem;
  @Input() image: any;

  @Output() batchToggleItems = new EventEmitter();

  getIconFromType(type: string) {
    switch(type) {
      case 'StillImage':
        return 'photo_size_select_actual';

      case 'Video':
        return 'video';

      case 'Audio':
        return 'volume_up';

      case 'Modell':
        return '3d_rotation';

      case 'Dokument':
        return 'file_present';
    }
  }

  toggleItemSelected(event) {
    this.batchToggleItems.emit({
      index: this.index,
      state: !this.item.selected,
      shift: !!event.shiftKey
    });
  }
}
